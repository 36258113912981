import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { MilestoneQuery } from '@models/milestone-category/milestone/milestone.query';
import { groupBy } from 'lodash-es';
import { GqlService, listTimelineMilestonesQuery } from '@services/gql.service';
import { Utils } from '@services/utils';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';

import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { TimelineService } from '../state/timeline.service';
import { TimelineQuery } from '../state/timeline.query';
import { TimelineDialogFormValue } from '../timeline-dialog/timeline-dialog.model';
import { AgQuarterCloseApprovalComponent } from '../../../../../closing-page/tabs/quarter-close/ag-quarter-close-approval.component';

interface TimelineDependencyGridData {
  accept: boolean;
  name: string;
  months: string;
  current_start_date: string;
  current_end_date: string;
  revised_start_date: Date;
  revised_end_date: Date;
  milestone_id: string;
  timeline_milestone_id: string;
  query: listTimelineMilestonesQuery;
}

@Component({
  template: ` <div class="font-inter w-screen max-w-3xl">
    <h1 class="mb-2 text-xl font-semibold">Apply to Dependent Phases?</h1>
    <div class="text-aux-error text-xs mb-4">Not applying will remove the dependency</div>

    <div class="mb-12">
      <ag-grid-angular
        style="height: 300px"
        class="ag-theme-alpine"
        [rowData]="gridData"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
    </div>

    <button class="w-48 text-xs btn btn--blue" (click)="onSave()">
      <span class="spinner w-6 h-6 mr-3" *ngIf="loading$ | async"></span>
      <span>Save Milestone Changes</span>
    </button>
  </div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDependencyComponent {
  gridData: TimelineDependencyGridData[] = [];

  gridOptions = {
    defaultColDef: {
      sortable: false,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
    },
    suppressMenuHide: true,
    headerHeight: 40,
    suppressCellFocus: true,
    columnTypes: Utils.columnTypes,
    columnDefs: [
      {
        headerName: 'ACCEPT CHANGE?',
        field: 'accept',
        maxWidth: 90,
        // todo this is fine for now but create a new ag checkbox component in the future.
        cellRenderer: AgQuarterCloseApprovalComponent,
      },
      {
        headerName: 'NAME',
        field: 'name',
        maxWidth: 150,
      },
      {
        headerName: 'CURRENT START DATE',
        field: 'current_start_date',
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        type: 'date',
      },
      {
        headerName: 'CURRENT END DATE',
        field: 'current_end_date',
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        type: 'date',
      },
      {
        headerName: 'REVISED START DATE',
        field: 'revised_start_date',
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        type: 'date',
        cellClass: ['text-aux-blue'],
        headerClass: ['text-aux-blue'],
      },
      {
        headerName: 'REVISED END DATE',
        field: 'revised_end_date',
        valueFormatter: (x) => Utils.dateFormatter(x.value),
        type: 'date',
        cellClass: ['text-aux-blue'],
        headerClass: ['text-aux-blue'],
      },
    ],
  } as GridOptions;

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridColumnApi$ = new ReplaySubject<ColumnApi>(1);

  gridColumnApi!: ColumnApi;

  loading$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<
      any,
      {
        oldFormValue: TimelineDialogFormValue;
        newFormValue: TimelineDialogFormValue;
        id: string;
        milestone_id: string;
        field_name: 'revised' | 'contract' | 'actual';
      }
    >,
    private timelineService: TimelineService,
    public milestoneQuery: MilestoneQuery,
    private timelineQuery: TimelineQuery,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private gqlService: GqlService
  ) {
    if (this.ref.data) {
      const { milestone_id, newFormValue, oldFormValue, field_name } = this.ref.data;

      const { items } = this.timelineQuery.getValue();

      const groupedItems = groupBy(items, 'track_from_milestone.id');

      const idsToLookFor = [milestone_id];
      const timelinesToCalculate: listTimelineMilestonesQuery[] = [];
      while (idsToLookFor.length) {
        const item_id = idsToLookFor.shift();
        if (item_id) {
          (groupedItems[item_id] || []).forEach((item) => {
            timelinesToCalculate.push(item);
            idsToLookFor.push(item.milestone.id);
          });
        } else {
          break;
        }
      }

      const t2 = new Date(
        // @ts-ignore
        oldFormValue[`${field_name}_end_date`] || oldFormValue.contract_end_date
      ).getTime();
      // @ts-ignore
      const t1 = new Date(newFormValue[`${field_name}_end_date`] as string).getTime();
      const dateDiff = Math.floor((t1 - t2) / (24 * 3600 * 1000));

      this.gridData = timelinesToCalculate.map((item) => {
        const milestone = this.milestoneQuery.getEntity(item.milestone.id);
        // @ts-ignore
        const start_date = (item[`${field_name}_start_date`] || item.contract_start_date) as string;
        // @ts-ignore
        const end_date = (item[`${field_name}_end_date`] || item.contract_end_date) as string;

        return {
          accept: true,
          current_start_date: start_date,
          current_end_date: end_date,
          months: '',
          name: milestone?.name || '',
          revised_start_date: Utils.addDays(Utils.dateParse(start_date), dateDiff),
          revised_end_date: Utils.addDays(Utils.dateParse(end_date), dateDiff),
          milestone_id: item.milestone.id,
          timeline_milestone_id: item.id,
          query: item,
        };
      });
    }
  }

  async onSave() {
    if (this.loading$.getValue()) {
      return;
    }
    this.loading$.next(true);
    const rows: RowNode[] = [];
    this.gridAPI.forEachNode((row) => rows.push(row));
    const proms = [];
    const inputs = [];

    if (this.ref.data) {
      const { field_name } = this.ref.data;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const {
          accept,
          revised_start_date,
          revised_end_date,
          query,
          timeline_milestone_id,
        } = row.data as TimelineDependencyGridData;
        if (accept) {
          const prevRowData = (rows[i - 1] as RowNode | undefined)?.data as
            | TimelineDependencyGridData
            | undefined;

          proms.push(
            // if accepted just change the revised dates.
            this.timelineService.updateTimelineForDependency({
              id: timeline_milestone_id,
              field_name,
              contract_start_date: Utils.awsDateFormatter(revised_start_date),
              contract_end_date: Utils.awsDateFormatter(revised_end_date),
              track_from_milestone_id:
                prevRowData && !prevRowData.accept
                  ? '00000000-0000-0000-0000-000000000000'
                  : query.track_from_milestone?.id || null,
            })
          );
          inputs.push({
            id: timeline_milestone_id,
            field_name,
            contract_start_date: Utils.awsDateFormatter(revised_start_date),
            contract_end_date: Utils.awsDateFormatter(revised_end_date),
            track_from_milestone_id:
              prevRowData && !prevRowData.accept
                ? '00000000-0000-0000-0000-000000000000'
                : query.track_from_milestone?.id || null,
          });
        } else {
          // TODO test this branch
          proms.push(
            // if didn't accepted remove the track from
            this.timelineService.updateTimelineForDependency({
              id: timeline_milestone_id,
              field_name,
              contract_start_date: query.contract_start_date,
              contract_end_date: query.contract_end_date,
              track_from_milestone_id: '00000000-0000-0000-0000-000000000000',
            })
          );
          inputs.push({
            id: timeline_milestone_id,
            field_name,
            contract_start_date: query.contract_start_date,
            contract_end_date: query.contract_end_date,
            track_from_milestone_id: '00000000-0000-0000-0000-000000000000',
          });
        }
      }
      await Promise.all(proms);
      this.loading$.next(false);
      this.ref.close(inputs);
    }
  }

  onGridReady({ api, columnApi }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    this.gridColumnApi$.next(columnApi);
    this.gridColumnApi = columnApi;
    api.sizeColumnsToFit();
  }
}
