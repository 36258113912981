import { Injectable } from '@angular/core';
import { combineQueries, EntityUIQuery, HashMap, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { memo } from 'helpful-decorators';
import { listTimelineMilestonesQuery } from '@services/gql.service';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { round } from 'lodash-es';

import { CategoryStore, CategoryState, CategoryModel, CategoryUIState } from './category.store';
import { ForecastSettingsQuery } from '../settings/forecast-settings.query';
import { ForecastSettingsModel } from '../settings/forecast-settings.store';
import { ActivityQuery } from '../activity/activity.query';
import { ActivityModel } from '../activity/activity.store';

export interface FullSettings extends Omit<ForecastSettingsModel, 'milestone_category'> {
  milestone_category: { name: string; id: string } | null;
  start_milestone: { name: string; id: string } | null;
  end_milestone: { name: string; id: string } | null;
}

export interface FullActivity extends ActivityModel {
  primary_settings: FullSettings;
  secondary_settings: FullSettings;
}

export interface FullCategory extends CategoryModel {
  primary_settings: FullSettings;
  secondary_settings: FullSettings;
  activities: FullActivity[];
}

interface ForecastFilters {
  hideActivitiesWithNoRemainingCost: boolean;
  hideForecastedActivities: boolean;
}

@Injectable({ providedIn: 'root' })
export class CategoryQuery extends QueryEntity<CategoryState> {
  ui!: EntityUIQuery<CategoryUIState>;

  filters$ = combineLatest([
    this.store.hideActivitiesWithNoRemainingCost$,
    this.store.hideForecastedActivities$,
  ]).pipe(
    map((arr) => {
      return {
        hideActivitiesWithNoRemainingCost: arr[0],
        hideForecastedActivities: arr[1],
      };
    })
  );

  topCategories$ = this.selectAll({ filterBy: (entity) => entity.parent_category_id === null });

  selectTopCategories$: Observable<FullCategory[]> = combineQueries([
    this.selectAll({ filterBy: (entity) => entity.parent_category_id === null }),
    this.forecastSettingsQuery.selectAll({ asObject: true }),
    this.activityQuery.selectAll({ asObject: true }),
    this.timelineQuery.select('items'),
    this.filters$,
  ]).pipe(
    map(([categories, settings, activities, timelineMilestones, filters]) => {
      return this.filterFullCategory(
        categories.map((category) => {
          return this.categoryMapper([category, settings, activities, timelineMilestones]);
        }),
        filters
      );
    })
  );

  @memo()
  selectCategory(cat_id: string): Observable<FullCategory | null> {
    return combineQueries([
      this.selectEntity(cat_id),
      this.forecastSettingsQuery.selectAll({ asObject: true }),
      this.activityQuery.selectAll({ asObject: true }),
      this.timelineQuery.select('items'),
      this.filters$,
    ]).pipe(
      map(([category, settings, activity, timelineMilestones, filters]) => {
        if (category) {
          return this.filterActivitiesAndSubCategories(
            this.categoryMapper([category, settings, activity, timelineMilestones]),
            filters
          );
        }
        return null;
      })
    );
  }

  filterActivitiesAndSubCategories(cat: FullCategory, filters: ForecastFilters) {
    const activities = cat.activities.filter((act) =>
      this.filterCatOrAct(act.primary_settings, filters)
    );
    const sub_categories = cat.sub_category_ids.filter((cat_id) => {
      const sub_cat = this.getEntity(cat_id);
      const primary_settings = this.forecastSettingsQuery.getEntity(
        sub_cat?.primary_settings_id || ''
      );
      if (primary_settings) {
        return this.filterCatOrAct((primary_settings as unknown) as FullSettings, filters);
      }

      return false;
    });
    return { ...cat, activities, sub_categories };
  }

  filterCatOrAct(settings: FullSettings, filters: ForecastFilters) {
    const { unforecasted } = this.forecastSettingsQuery.ui.getEntity(settings.id) || {};
    if (filters.hideForecastedActivities && !unforecasted) {
      return false;
    }

    if (filters.hideActivitiesWithNoRemainingCost) {
      const remaining = round(settings.total_cost - settings.total_wp, 2);
      if (!remaining) {
        return false;
      }
    }

    return true;
  }

  filterFullCategory(cats: FullCategory[], filters: ForecastFilters) {
    return cats
      .filter((cat) => this.filterCatOrAct(cat.primary_settings, filters))
      .map((cat) => this.filterActivitiesAndSubCategories(cat, filters));
  }

  categoryMapper([category, settings, activity, timelineMilestones]: [
    CategoryModel,
    HashMap<ForecastSettingsModel>,
    HashMap<ActivityModel>,
    listTimelineMilestonesQuery[]
  ]) {
    const primary_setting = settings[category.primary_settings_id];
    const secondary_setting =
      settings[category.secondary_settings_id] || ({} as ForecastSettingsModel);
    const mils = timelineMilestones.reduce((acc, val) => {
      acc[val.milestone.id] = val;
      return acc;
    }, {} as Record<string, listTimelineMilestonesQuery>);
    const categories = timelineMilestones.reduce((acc, val) => {
      acc[val.milestone.milestone_category_id] = {
        ...val.milestone.milestone_category,
        id: val.milestone.milestone_category_id,
      };
      return acc;
    }, {} as Record<string, { name: string; id: string }>);
    const fullCategory: FullCategory = {
      ...category,
      primary_settings: {
        ...primary_setting,
        milestone_category: primary_setting?.milestone_category
          ? categories[primary_setting.milestone_category]
          : null,
        start_milestone: primary_setting?.period_start_milestone_id
          ? mils[primary_setting.period_start_milestone_id]?.milestone
          : null,
        end_milestone: primary_setting?.period_end_milestone_id
          ? mils[primary_setting.period_end_milestone_id]?.milestone
          : null,
      },
      secondary_settings: {
        ...secondary_setting,
        milestone_category: secondary_setting?.milestone_category
          ? categories[secondary_setting.milestone_category]
          : null,
        start_milestone: secondary_setting?.period_start_milestone_id
          ? mils[secondary_setting.period_start_milestone_id]?.milestone
          : null,
        end_milestone: secondary_setting?.period_end_milestone_id
          ? mils[secondary_setting.period_end_milestone_id]?.milestone
          : null,
      },
      activities: category.activity_ids.map((act_id) => {
        const act_primary_settings = settings[activity[act_id].primary_settings_id];
        const act_secondary_settings =
          settings[activity[act_id].secondary_settings_id] || ({} as ForecastSettingsModel);
        const fullActivity: FullActivity = {
          ...activity[act_id],
          primary_settings: {
            ...act_primary_settings,
            milestone_category: act_primary_settings?.milestone_category
              ? categories[act_primary_settings.milestone_category]
              : null,
            start_milestone: act_primary_settings?.period_start_milestone_id
              ? mils[act_primary_settings.period_start_milestone_id]?.milestone
              : null,
            end_milestone: act_primary_settings?.period_end_milestone_id
              ? mils[act_primary_settings.period_end_milestone_id]?.milestone
              : null,
          },
          secondary_settings: {
            ...act_secondary_settings,
            milestone_category: act_secondary_settings?.milestone_category
              ? categories[act_secondary_settings.milestone_category]
              : null,
            start_milestone: act_secondary_settings?.period_start_milestone_id
              ? mils[act_secondary_settings.period_start_milestone_id]?.milestone
              : null,
            end_milestone: act_secondary_settings?.period_end_milestone_id
              ? mils[act_secondary_settings.period_end_milestone_id]?.milestone
              : null,
          },
        };
        return fullActivity;
      }),
    };
    return fullCategory;
  }

  constructor(
    protected store: CategoryStore,
    private forecastSettingsQuery: ForecastSettingsQuery,
    private activityQuery: ActivityQuery,
    private timelineQuery: TimelineQuery
  ) {
    super(store);

    this.createUIQuery();
  }
}
