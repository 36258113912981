<section
  class="border-2 rounded-md h-24 p-2 relative mt-4"
  *ngFor="let file of files$ | async; first as first"
  [ngClass]="{ 'mt-4': disableFirstFileMargin ? !first : true }"
>
  <div class="flex items-center justify-between h-6">
    <div inlineSVG="documents.svg" class="-ml-1 w-6 h-6"></div>
    <div>
      <button class="aux-link focus:outline-none" (click)="onRemove(file)">Remove</button>
    </div>
  </div>
  <div class="h-12 mt-2">
    <div class="font-semibold line-clamp-2">{{ file.fileName }}</div>
    <ng-content></ng-content>
  </div>
</section>

<ng-container *ngIf="loading$ | async">
  <div class="border border-blue-200 shadow rounded-md p-2 max-w-sm w-full mt-4">
    <div class="animate-pulse flex space-x-4">
      <div class="flex-1 space-y-4 py-1">
        <div class="flex justify-between">
          <div class="h-8 w-7 bg-blue-200 rounded"></div>
          <div class="flex-grow"></div>
          <div class="h-4 bg-blue-200 rounded w-1/6"></div>
        </div>
        <div class="space-y-2">
          <div class="h-3 bg-blue-200 rounded w-3/6"></div>
          <div class="h-0.5"></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
