export const ROUTING_PATH = {
  BUDGET: {
    INDEX: 'budget',
    ENHANCED: 'enhanced',
    LIBRARY: 'library',
    CHANGE_LOG: 'change-log',
    CHANGE_ORDER: 'change-orders',
    COMPARE: 'compare',
  },
  CLOSING: {
    INDEX: 'period-close',
    QUARTER_CLOSE: 'quarter-close',
    RECONCILIATION: 'reconciliation',
  },
  VENDOR_PAYMENTS: {
    INDEX: 'vendor-payments',
    INVOICES: 'invoices',
    PURCHASE_ORDERS: 'purchase-orders',
    PAYMENT_SCHEDULE: 'payment-schedule',
    VENDORS: 'vendors',
    PAYMENT_MILESTONES: 'payment-milestones',
  },
  FORECAST_ROUTING: {
    INDEX: 'forecast',
    FORECAST_METHODOLOGY: 'forecast-methodology',
    STUDY_SPECIFICATIONS: 'study-specifications',
    TIMELINE: {
      INDEX: 'timeline',
      TRIAL_TIMELINE: 'trial-timeline',
      PAYMENT_MILESTONES: 'payment-milestones',
    },
    PATIENT_DRIVER: {
      INDEX: 'patient-drivers',
      CURVES: 'patient-curves',
      GROUPS: 'patient-groups',
    },
    SITE_DRIVER: {
      INDEX: 'site-drivers',
      CURVES: 'site-curves',
      GROUPS: 'site-groups',
    },
  },
  MANAGER: 'scenario-manager',
  AUDIT_HISTORY: 'audit-history',
  DOCUMENTS: 'document-library',
  SITES: {
    INDEX: 'sites',
    INVESTIGATOR_FORECAST: 'investigator-forecast',
    INVESTIGATOR_DETAIL: 'investigator-detail',
    TRIAL_SITES: 'sites',
  },
  PATIENTS: {
    INDEX: 'patients',
    PATIENT_TRACKER: 'patient-tracker',
    PATIENT_BUDGET: {
      INDEX: 'patient-budget',
      VISIT_COSTS: 'visit-costs',
      OTHER_COSTS: 'other-costs',
    },
    PATIENT_BUDGET_ENTRY: 'patient-budget-entry',
  },
  SETTINGS: {
    INDEX: 'settings',
    USERS: 'users',
    USER_PERMISSIONS: 'user-permissions',
    EXCHANGE_RATES: 'exchange-rates',
    INTEGRATIONS: 'integrations',
  },
  ONBOARDING: {
    INDEX: 'onboarding',
    VENDOR: 'vendor',
    USER: 'user',
    SITE: 'site',
    USER_PREFERENCES: 'user-preferences',
    PROCESSING: 'processing',
  },
  LOGIN: 'login',
  CONFIRMATION: 'confirmation',
  HOME: 'home',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ACCOUNT: {
    INDEX: 'account',
    SETTINGS: 'settings',
    NOTIFICATIONS: 'notifications',
  },
  DASHBOARD: 'dashboard',
  RISK_ANALYTICS: 'risk-analytics',
};
