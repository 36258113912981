import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { OrganizationService } from '@models/organization/organization.service';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationType } from '@services/gql.service';

@Component({
  selector: 'aux-multiple-organizations-dialog',
  templateUrl: './multiple-organizations-dialog.html',
  styleUrls: ['multiple-organizations-dialog.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleOrganizationsDialogComponent {
  @ViewChild('vendors') vendors!: InputComponent;

  isLoading$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<any, null>,
    private overlayService: OverlayService,
    private organizationService: OrganizationService,
    private organizationStore: OrganizationStore
  ) {
    this.ref.canDeactivate = this.canDeactivate;
  }

  canDeactivate = async () => {
    if (this.vendors.fc.value) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();

      return !!event.data;
    }

    return true;
  };

  getExistingVendorNames() {
    return Object.values(this.organizationStore.getValue().entities || {})
      .reduce((accumulator, { organization_type, name }) => {
        if (organization_type === OrganizationType.ORGANIZATION_VENDOR) {
          return [...accumulator, name as string];
        }

        return accumulator;
      }, [] as string[])
      .map((vendorName) => vendorName.toLowerCase());
  }

  addVendors = async () => {
    if (!this.vendors.fc.value) {
      this.vendors.fc.markAsTouched();
      this.vendors.fc.setErrors({ emptyVendors: true });

      return;
    }

    const vendorNames = this.vendors.fc.value.split('\n').filter((vendor: string) => vendor);
    const lowerCaseNames = vendorNames.map((vendor: string) => vendor.toLowerCase());
    const existingVendorNames = this.getExistingVendorNames();
    const hasDuplicatedNewNames = lowerCaseNames.length !== new Set(lowerCaseNames).size;
    const hasDuplicatedWithExistingNames = lowerCaseNames.some((vendorName: string) =>
      existingVendorNames.includes(vendorName)
    );

    if (hasDuplicatedNewNames || hasDuplicatedWithExistingNames) {
      this.vendors.fc.setErrors({ duplicatedVendors: true });

      return;
    }

    this.isLoading$.next(true);

    await Promise.all(
      vendorNames.map((vendor: string) =>
        this.organizationService.add(vendor, {
          given_name: '',
          family_name: '',
          email: '',
          title: '',
          phone_number: '',
          parent_organization_id: '',
          costs_included_in_parent_wo: false,
        })
      )
    ).then((results) => {
      let hasErrors = false;

      (results as GraphqlResponse<any>[]).forEach(({ success, errors }) => {
        if (!success) {
          this.overlayService.error(errors);
          hasErrors = true;
        }
      });

      if (!hasErrors) {
        this.ref.canDeactivate = () => true;
        this.overlayService.success('Vendors successfully added!');
        this.ref.close();
      }

      this.isLoading$.next(false);
    });
  };
}
