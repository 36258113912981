import { Injectable } from '@angular/core';
import { isObject, isArray } from 'lodash-es';
import { AnalyticsCardType, GqlService } from '@services/gql.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EventService } from '@services/event.service';

import { createInitialState, ForecastStore } from './forecast.store';

interface UnforecastedCosts {
  total_cost: number;
  activity_count: number;
}

interface ActivityByDriver {
  driver: 'Time' | 'Site' | 'Patient' | 'N/A';
  cost: number;
  percentage: number;
}

@Injectable({ providedIn: 'root' })
export class ForecastService {
  constructor(
    private forecastStore: ForecastStore,
    private gqlService: GqlService,
    private organizationQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private eventService: EventService
  ) {}

  getActivitiesByDriver() {
    return this.organizationQuery.selectActive().pipe(
      switchMap((org) => {
        if (org) {
          const bv = this.organizationQuery.getPrimaryBudgetVersion(org.id);
          if (!bv?.manual_forecast) {
            return this.eventService.selectAnalyticsCard$({
              analytics_card_type: AnalyticsCardType.FORECAST_ACTIVITIES_BY_DRIVER,
              vendor_id: org.id,
              budget_version_id: bv?.budget_version_id,
            });
          }
        }
        return EMPTY;
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          this.forecastStore.update({
            activitiesByDriver: this.parseActivitiesByDriverResponse(data.data),
          });
        } else {
          this.forecastStore.update({
            activitiesByDriver: createInitialState().activitiesByDriver,
          });
          if (errors?.length) {
            this.overlayService.error(errors);
          }
        }
      })
    );
  }

  getUnforecastedCosts() {
    return this.organizationQuery.selectActive().pipe(
      switchMap((organization) => {
        if (organization) {
          const budgetVersion = this.organizationQuery.getPrimaryBudgetVersion(organization.id);

          if (!budgetVersion?.manual_forecast) {
            return this.eventService.selectAnalyticsCard$({
              analytics_card_type: AnalyticsCardType.UNFORECASTED_ACTIVITIES_COST,
              vendor_id: organization.id,
              budget_version_id: budgetVersion?.budget_version_id,
            });
          }
        }

        return EMPTY;
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          this.forecastStore.update({
            unforecastedCosts: this.parseUnforecastedCostsResponse(data.data),
          });
        } else {
          this.forecastStore.update({
            unforecastedCosts: createInitialState().unforecastedCosts,
          });

          if (errors?.length) {
            this.overlayService.error(errors);
          }
        }
      })
    );
  }

  parseUnforecastedCostsResponse(data: string | UnforecastedCosts) {
    return (isObject(data) ? data : JSON.parse(data)).total_cost;
  }

  parseActivitiesByDriverResponse(data: string | ActivityByDriver[]) {
    const arr = isArray(data) ? data : (JSON.parse(data) as ActivityByDriver[]);

    const response = createInitialState();

    arr.forEach(({ driver, percentage, cost }) => {
      if (Object.prototype.hasOwnProperty.call(response.activitiesByDriver, driver)) {
        // @ts-ignore
        response.activitiesByDriver[driver] = {
          cost,
          costStr: Utils.currencyFormatter(cost, {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            notation: 'compact',
          }),
          percentage,
        };
      }
    });

    return response.activitiesByDriver;
  }
}
