import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {
  CellClickedEvent,
  ColumnApi,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowNode,
} from 'ag-grid-community';
import { OverlayService } from '@services/overlay.service';
import { OrganizationService } from '@models/organization/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, startWith, switchMap } from 'rxjs/operators';
import {
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  listUserNamesWithEmailQuery,
  User,
} from '@services/gql.service';
import {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Utils } from '@services/utils';
import { OrganizationStore } from '@models/organization/organization.store';
import { FormControl } from '@angular/forms';
import { ApiService, AwsFile } from '@services/api.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject, combineLatest, Observable, merge } from 'rxjs';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EventService } from 'src/app/services/event.service';
import { AuthQuery } from '@models/auth/auth.query';

import { Router } from '@angular/router';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { AgInvoiceActionsComponent } from './ag-invoice-actions/ag-invoice-actions.component';
import { NewInvoiceDialogComponent } from './new-invoice-dialog/new-invoice-dialog.component';
import { UploadDocumentsDialogComponent } from './upload-documents-dialog/upload-documents-dialog.component';
import { InvoiceService } from './state/invoice.service';
import { InvoiceQuery } from './state/invoice.query';
import { VendorPaymentsPageComponent } from '../../vendor-payments-page.component';
import { PurchaseOrdersService } from '../purchase-orders/state/purchase-orders.service';
import { PurchaseOrdersQuery } from '../purchase-orders/state/purchase-orders.query';
import { InvoicesStatusComponent } from './invoices-status.component';
import { PaymentStatusComponent } from './payment-status.component';
import { AgHeaderActionsComponent } from './ag-invoice-actions/ag-header-actions.component';
import { InvoicesGridFormatterService } from './invoices-grid-formatter.service';
import { TableConstants } from '../../../../constants/table.constants';
import { ROUTING_PATH } from '../../../../app-routing-path.const';
import { InvoiceModel } from './state/invoice.model';
import { WORKFLOW_NAMES } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list';
import { WorkflowQuery } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';

@UntilDestroy()
@Component({
  selector: 'aux-invoices',
  templateUrl: './invoices.component.html',
})
export class InvoicesComponent implements OnInit, AfterViewInit {
  @ViewChildren('invoiceTemp') invoiceTemp!: QueryList<TemplateRef<any>>;

  excelOptions: ExcelExportParams = {
    sheetName: 'Invoices',
    fileName: 'auxilius-invoices.xlsx',
  };

  workflowName = WORKFLOW_NAMES.INVOICES;

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  iCloseMonthsProcessing$ = this.mainQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isAdminUser$ = this.authQuery.adminUser$.pipe(untilDestroyed(this));

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowName(this.workflowName);

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  gridOptions$ = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressMenuHide: true,
    columnDefs: [
      {
        headerName: '',
        field: 'file',
        cellRenderer: AgInvoiceActionsComponent,
        cellRendererParams: {
          isInvoiceFinalized$: this.isInvoiceFinalized$,
          iCloseMonthsProcessing$: this.iCloseMonthsProcessing$,
          invoiceLockTooltip$: this.invoiceLockTooltip$,
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.removeInvoice(rowNode);
          },
          downloadClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            this.downloadInvoiceItems(rowNode);
          },
          uploadClickFN: async (invoice: InvoiceModel) => {
            await this.overlayService.open({
              content: UploadDocumentsDialogComponent,
              data: { invoice },
            });
          },
          hideDeleteButton: !this.authQuery.getValue().is_admin,
        },
        width: 90,
        suppressSizeToFit: true,
        cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
      },
      {
        headerName: 'Invoice #',
        field: 'invoice_no',
        valueFormatter: Utils.dashFormatter,
        onCellClicked: (event: CellClickedEvent) => this.goToInvoiceDetail(event),
        cellClass: 'aux-link cursor-pointer',
        minWidth: 175,
      },
      {
        headerName: 'Vendor',
        field: 'organization.name',
        minWidth: 175,
        valueFormatter: Utils.dashFormatter,
        cellClass: 'text-left',
      },
      {
        headerName: 'Invoice Total',
        field: 'expense_amounts.invoice_total.value',
        cellClass: ['ag-cell-align-right', 'cost'],
        valueFormatter: Utils.agCurrencyFormatter,
        headerComponent: AgHeaderActionsComponent,
        minWidth: 150,
      },
      {
        headerName: 'Services Total',
        field: 'expense_amounts.services_total.value',
        cellClass: ['ag-cell-align-right', 'cost'],
        valueFormatter: Utils.agCurrencyFormatter,
        hide: true,
        width: 125,
      },
      {
        headerName: 'Discount Total',
        field: 'expense_amounts.discount_total.value',
        cellClass: ['ag-cell-align-right', 'cost'],
        valueFormatter: Utils.agCurrencyFormatter,
        hide: true,
        width: 125,
      },
      {
        headerName: 'Investigator Total',
        field: 'expense_amounts.investigator_total.value',
        cellClass: ['ag-cell-align-right', 'cost'],
        valueFormatter: Utils.agCurrencyFormatter,
        hide: true,
        width: 150,
      },
      {
        headerName: 'Pass-Through Total',
        field: 'expense_amounts.pass_thru_total.value',
        cellClass: ['ag-cell-align-right', 'cost'],
        valueFormatter: Utils.agCurrencyFormatter,
        hide: true,
        width: 160,
      },
      {
        headerName: 'Status',
        field: 'invoice_status',
        colId: 'invoice_status',
        minWidth: 150,
        maxWidth: 150,
        cellRenderer: InvoicesStatusComponent,
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value),
      },
      {
        headerName: 'Invoice Date',
        field: 'invoice_date',
        valueFormatter: Utils.agDateFormatter,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Due Date',
        field: 'due_date',
        valueFormatter: Utils.agDateFormatter,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: 'PO#',
        field: 'po_reference',
        colId: 'po_reference',
        cellClass: ['align-right'],
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) =>
          this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
      },
      {
        headerName: 'Date Created',
        field: 'create_date',
        colId: 'create_date',
        valueFormatter: Utils.agDateFormatter,
        getQuickFilterText: Utils.agDateFormatter,
        sort: 'desc',
        minWidth: 120,
        maxWidth: 120,
      },
      {
        headerName: 'Created by',
        field: 'created_by',
        colId: 'created_by',
        valueFormatter: (val: ValueFormatterParams) =>
          this.invoicesGridFormatterService.getFormatterCreateAuthor(val, this.userFormatter),
        getQuickFilterText: (params: GetQuickFilterTextParams) => this.userFormatter(params.value),
        minWidth: 150,
        cellClass: 'text-left',
      },
      {
        headerName: 'Require Cost Breakdown',
        field: 'requireCostBreakdown',
        colId: 'requireCostBreakdown',
        hide: true,
        filter: true,
      },
    ],
    excelStyles: [
      ...Utils.auxExcelStyle,
      { id: 'align-right', alignment: { horizontal: 'Right' } },
    ],
  } as GridOptions);

  queueData$ = this.invoiceQuery.selectAll({
    filterBy: (entity) => entity.invoice_status === InvoiceStatus.STATUS_IN_QUEUE,
  });

  gridData$: any = null;

  gridAPI!: GridApi;

  listData$ = this.invoiceQuery
    .selectAll({
      filterBy: (entity) => {
        const { is_admin } = this.authQuery.getValue();
        if (is_admin) {
          return (
            entity.invoice_status === InvoiceStatus.STATUS_PENDING_REVIEW ||
            entity.invoice_status === InvoiceStatus.STATUS_PENDING_APPROVAL
          );
        }
        return entity.invoice_status === InvoiceStatus.STATUS_PENDING_APPROVAL;
      },
    })
    .pipe(
      switchMap((listData) => {
        return this.selectedVendor.valueChanges.pipe(
          startWith(this.selectedVendor.value as string),
          map((selected_organization) => {
            if (selected_organization) {
              return listData.filter((invoice) => {
                return invoice.organization.id === selected_organization;
              });
            }

            return listData;
          })
        );
      })
    );

  selectedVendor = new FormControl('');

  showIntegrationInvoiceConfiguration$: Observable<boolean>;

  billComIntegrationEnabled$: Observable<boolean>;

  quickbooksOnlineIntegrationEnabled$: Observable<boolean>;

  loadingFetchBillCom$ = new BehaviorSubject(false);

  loadingFetchQuickbooksOnline$ = new BehaviorSubject(false);

  filesLoading$ = new BehaviorSubject(false);

  files$ = new BehaviorSubject<AwsFile[]>([]);

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  nameFilterValue = '';

  constructor(
    private apiService: ApiService,
    public organizationQuery: OrganizationQuery,
    private organizationStore: OrganizationStore,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private invoiceService: InvoiceService,
    private eventService: EventService,
    private vendorsService: OrganizationService,
    public invoiceQuery: InvoiceQuery,
    private vendorPaymentsPageComponent: VendorPaymentsPageComponent,
    private purchaseOrdersService: PurchaseOrdersService,
    private purchaseOrdersQuery: PurchaseOrdersQuery,
    public authQuery: AuthQuery,
    private router: Router,
    private ld: LaunchDarklyService,
    private gqlService: GqlService,
    private trialUserService: TrialUserService,
    private invoicesGridFormatterService: InvoicesGridFormatterService,
    private workflowQuery: WorkflowQuery
  ) {
    combineLatest([this.trialUserService.listUserNamesWithEmail()])
      .pipe(untilDestroyed(this))
      .subscribe(([_users]) => {
        _users?.data?.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
    this.purchaseOrdersService.get().pipe(untilDestroyed(this)).subscribe();
    this.showIntegrationInvoiceConfiguration$ = this.ld.select$(
      (flags) =>
        !!flags.cronjob_bill_com_integration || !!flags.cronjob_quickbooks_online_integration
    );

    this.billComIntegrationEnabled$ = this.ld.select$(
      (flags) => !!flags.cronjob_bill_com_integration
    );
    this.quickbooksOnlineIntegrationEnabled$ = this.ld.select$(
      (flags) => !!flags.cronjob_quickbooks_online_integration
    );

    this.showIntegrationInvoiceConfiguration$.pipe(untilDestroyed(this)).subscribe((enabled) => {
      if (enabled) {
        const currentGrid = this.gridOptions$.getValue();
        const paymentColumn = {
          headerName: 'Payment Status',
          field: 'payment_status',
          colId: 'payment_status',
          cellRenderer: PaymentStatusComponent,
          minWidth: 175,
          maxWidth: 175,
          getQuickFilterText: (params: GetQuickFilterTextParams) =>
            this.invoicesGridFormatterService.getFormattedPaymentStatus(params.value),
        };
        const hasPaymentCol = currentGrid.columnDefs?.filter(
          (col) => col.headerName === paymentColumn.headerName
        ).length;
        if (!hasPaymentCol) {
          const paymentColIdx = 8;
          currentGrid.columnDefs?.splice(paymentColIdx, 0, paymentColumn);
          this.gridOptions$.next(currentGrid);
        }
      }
    });
  }

  ngOnInit() {
    this.vendorsService
      .get()
      .pipe(
        switchMap(() => {
          this.filesLoading$.next(true);
          const { trialKey } = this.mainQuery.getValue();
          return this.apiService.listFiles(
            `trials/${trialKey}/vendors/`,
            undefined,
            EntityType.INVOICE,
            DocumentType.DOCUMENT_INVOICE,
            true
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((value: AwsFile[]) => {
        this.filesLoading$.next(false);
        const files = value.map((x) => {
          const file = x.key.split('/');
          return { ...x, fileName: file[file.length - 1].slice(25) } as AwsFile;
        });

        this.files$.next(files);

        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });
    merge(
      this.eventService.select$(EventType.REFRESH_BILL_COM),
      this.eventService.select$(EventType.REFRESH_QUICKBOOKS_ONLINE),
      this.eventService.select$(EventType.INVOICE_UPDATED),
      this.eventService.select$(EventType.BULK_INVOICE_TEMPLATE_UPLOADED)
    )
      .pipe(
        startWith(null),
        switchMap(() => {
          return this.invoiceService.get();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.gridData$ = this.invoiceQuery.selectAll().pipe(
          switchMap((listData) => {
            return this.selectedVendor.valueChanges.pipe(
              startWith(this.selectedVendor.value as string),
              map((selected_organization) => {
                const ld2: any[] = [];
                listData.forEach((inv) => {
                  const in2 = {
                    ...inv,
                    file: null,
                    approved_by: inv.approvals ? inv.approvals[0]?.aux_user_id : null,
                    requireCostBreakdown:
                      inv.expense_amounts.investigator_total.value === 0 &&
                      inv.expense_amounts.pass_thru_total.value === 0 &&
                      inv.expense_amounts.services_total.value === 0 &&
                      inv.expense_amounts.discount_total.value === 0,
                  };
                  const file = this.files$.value.find((f) => f.key.indexOf(inv.id) >= 0);
                  ld2.push({ ...in2, file });
                });
                if (selected_organization) {
                  return ld2.filter((invoice) => {
                    return invoice.organization.id === selected_organization;
                  });
                }
                return ld2;
              })
            );
          })
        );
      });
  }

  customColFilter = (cel: ColDef | ColGroupDef) => !!cel.headerName;

  firstDataRendered({ api, columnApi }: { api: GridApi; columnApi: ColumnApi }) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
    Utils.updateGridLayout(this.gridAPI, 'invoicesGrid');
    // cant do this in agInit bc it was scrunch columns
    columnApi.setColumnsVisible(
      [
        'expense_amounts.investigator_total.value',
        'expense_amounts.pass_thru_total.value',
        'expense_amounts.services_total.value',
        'expense_amounts.discount_total.value',
      ],
      true
    );
  }

  async onNewInvoice() {
    await this.overlayService.open({ content: NewInvoiceDialogComponent });
  }

  async triggerFetchBillCom() {
    this.loadingFetchBillCom$.next(true);
    await this.gqlService
      .processEvent$({
        type: EventType.REFRESH_BILL_COM,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
      .toPromise();
    this.overlayService.success('Beginning Bill.com data retrieval...');
    this.loadingFetchBillCom$.next(false);
  }

  async triggerFetchQuickbooksOnline() {
    this.loadingFetchQuickbooksOnline$.next(true);
    await this.gqlService
      .processEvent$({
        type: EventType.REFRESH_QUICKBOOKS_ONLINE,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
      .toPromise();
    this.overlayService.success('Beginning Quickbooks Online data retrieval...');
    this.loadingFetchQuickbooksOnline$.next(false);
  }

  ngAfterViewInit(): void {
    this.invoiceTemp.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.vendorPaymentsPageComponent.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.vendorPaymentsPageComponent.rightSideContainer.next(this.invoiceTemp.first || null);
        }, 0);
      });
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
  }

  async removeInvoice(rowNode: RowNode) {
    if (!rowNode.data) {
      return;
    }
    const invoiceRow = this.invoiceQuery.getEntity(rowNode.data.id);

    if (!invoiceRow) {
      return;
    }

    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoice',
      message: `Are you sure you want to remove Invoice ${invoiceRow.invoice_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await resp.afterClosed$.toPromise();
    if (event.data?.result) {
      await this.invoiceService.remove(invoiceRow, event.data.textarea);
    }
  }

  goToInvoiceDetail(event: CellClickedEvent) {
    const id = event.data?.id;
    if (id) {
      // 1
      this.router.navigateByUrl(
        `${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}/${id}`
      );
    }
  }

  userFormatter = (sub: string | undefined): string => {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  };

  getDynamicExcelParams = (): ExcelExportParams => {
    if (!this.gridAPI) {
      return {};
    }

    const name = this.mainQuery.getSelectedTrial()?.short_name;
    return {
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const colId = params.column.getColId();

        const mapColFormatterById = new Map<string, () => string>([
          [
            'invoice_status',
            () => this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value),
          ],
          [
            'po_reference',
            () => this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
          ],
          [
            'payment_status',
            () => this.invoicesGridFormatterService.getFormattedPaymentStatus(params.value),
          ],
          [
            'created_by',
            () =>
              this.invoicesGridFormatterService.getFormatterCreateAuthor(
                { value: params.value, data: params.node?.data || {} } as ValueFormatterParams,
                this.userFormatter
              ),
          ],
          [
            'create_date',
            () => Utils.agDateFormatter({ value: params.value } as ValueFormatterParams),
          ],
        ]);

        const getFormatterCol = mapColFormatterById.get(colId);
        return getFormatterCol ? getFormatterCol() : params.value;
      },
      prependContent: [
        [
          {
            data: { value: `Trial: ${name}`, type: 'String' },
            mergeAcross: 1,
            styleId: 'first_row',
          },
        ],
      ],
    } as ExcelExportParams;
  };

  async downloadInvoiceItems(rowNode: RowNode) {
    const { key } = rowNode.data?.file;
    if (!key) {
      this.overlayService.error("This invoice doesn't have file");
      return;
    }
    const split = key.split('/');
    const newKey = `${split.slice(0, split.length - 2).join('/')}/`;
    const ref = this.overlayService.loading();
    const { success, data } = await this.apiService.getS3ZipFile(newKey);
    if (success && data) {
      const fileName =
        `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_Invoice_` +
        `${rowNode.data.id}_${(rowNode.data.create_date || '').slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
    ref.close();
  }

  checkRequireCostBreakdown(hide: boolean) {
    if (!this.gridAPI) {
      return;
    }
    const filterInstance = this.gridAPI.getFilterInstance('requireCostBreakdown');
    if (filterInstance) {
      filterInstance.setModel(hide ? { values: ['true'] } : null);
    }

    this.gridOptions$.getValue().api?.forEachNode((rowNode) => {
      if (hide) {
        if (rowNode.allChildrenCount && rowNode.allChildrenCount > 0) {
          rowNode.setRowHeight(0);
        }
      } else {
        rowNode.setRowHeight(35);
      }
    });

    this.gridAPI.onFilterChanged();
    this.gridAPI.onRowHeightChanged();
  }
}
