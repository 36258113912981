import {
  Component,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter, skip, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-menu',
  template: `
    <div>
      <div>
        <button
          type="button"
          class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          (click)="open($event)"
        >
          <span class="sr-only">Open options</span>
          <dots-vertical-solid-icon class="h-5 w-5" [size]="20"></dots-vertical-solid-icon>
        </button>
      </div>

      <ng-template #options>
        <div class="mt-4 rounded-md shadow-lg bg-white">
          <ng-content></ng-content>
        </div>
      </ng-template>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnDestroy {
  @ViewChild('options') options!: TemplateRef<any>;

  overlayRef: OverlayRef | undefined;

  constructor(public overlay: Overlay, public viewContainerRef: ViewContainerRef) {}

  open({ x, y }: MouseEvent) {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.overlayRef.attach(
      new TemplatePortal(this.options, this.viewContainerRef, {
        $implicit: 'test',
      })
    );

    fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        skip(1),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(() => this.close());
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  ngOnDestroy(): void {
    this.close();
  }
}
