import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import {
  Activity,
  ActivityType,
  BudgetExpenseData,
  BudgetType,
  Category,
  CategoryType,
  ExpenseType,
  GqlService,
  listInMonthCategoriesQuery,
} from '@services/gql.service';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import { round } from 'lodash-es';

import { AuthQuery } from '@models/auth/auth.query';
import { CategoryModel, CategoryStore } from './category.store';
import { ActivityStore } from '../activity/activity.store';
import { ForecastSettingsStore } from '../settings/forecast-settings.store';
import { CategoryQuery } from './category.query';
import { OverrideSettingsStore } from '../override-settings/override-settings.store';
import { ActivityQuery } from '../activity/activity.query';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  flatCategoriesLoading$ = new BehaviorSubject(false);

  markedCategoryIds: string[] = [];

  markedActivityIds: string[] = [];

  constructor(
    private categoryStore: CategoryStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private activityStore: ActivityStore,
    private forecastSettingsStore: ForecastSettingsStore,
    private organizationQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private categoryQuery: CategoryQuery,
    private activityQuery: ActivityQuery,
    private authQuery: AuthQuery,
    private overrideSettingsStore: OverrideSettingsStore
  ) {}

  fillObj(expenses: BudgetExpenseData[]) {
    const obj = {
      total_cost: 0,
      total_accrual: 0,
      total_forecast: 0,
      total_wp: 0,
      total_override: 0,
      adjustment: 0,
      total_override_adjusted: 0,
      total_forecast_at_close: 0,
    };

    let accrualExists = false;
    let forecastAtCloseExists = false;
    let expenseAccrualAdjustedExists = false;
    expenses.forEach((expense) => {
      switch (expense.expense_type) {
        case ExpenseType.EXPENSE_QUOTE: {
          obj.total_cost = round(expense.amount || 0, 2);
          break;
        }
        case ExpenseType.EXPENSE_ACCRUAL: {
          accrualExists = true;
          obj.total_accrual = round(expense.amount || 0, 2);
          break;
        }
        case ExpenseType.EXPENSE_FORECAST: {
          obj.total_forecast += round(expense.amount || 0, 2);
          break;
        }
        case ExpenseType.EXPENSE_WP: {
          if (expense.period === 'TO_DATE') {
            obj.total_wp = round(expense.amount || 0, 2);
          }
          break;
        }
        case ExpenseType.EXPENSE_ACCRUAL_OVERRIDE: {
          obj.total_override = round(expense.amount || 0, 2);
          break;
        }
        case ExpenseType.EXPENSE_ACCRUAL_ADJUSTED: {
          expenseAccrualAdjustedExists = true;
          obj.total_override_adjusted = round(expense.amount || 0, 2);
          break;
        }
        case ExpenseType.EXPENSE_FORECAST_AT_CLOSE: {
          forecastAtCloseExists = true;
          obj.total_forecast_at_close = round(expense.amount || 0, 2);
          break;
        }
        default:
          break;
      }
    });

    if (forecastAtCloseExists) {
      obj.total_forecast = obj.total_forecast_at_close;
    }

    obj.adjustment = round(
      !expenseAccrualAdjustedExists
        ? 0
        : obj.total_override_adjusted - (accrualExists ? obj.total_accrual : obj.total_forecast),
      2
    );

    return obj;
  }

  categoryFilterExpenses(category: Pick<Category, 'expenses' | 'category_type'>) {
    return (category.expenses || [])?.filter(
      (expense) => (expense.amount_type || '').substr(7) === category.category_type.substr(9)
    );
  }

  activityFilterExpenses(activity: Pick<Activity, 'expenses' | 'activity_type'>) {
    return (activity.expenses || [])?.filter(
      (expense) => (expense.amount_type || '').substr(7) === activity.activity_type.substr(9)
    );
  }

  getCategoriesForInMonth(
    month: string | null
  ): Observable<
    { success: boolean; data: listInMonthCategoriesQuery | null; errors: string[] }[] | null
  > {
    return combineLatest([
      this.organizationQuery.selectActive(),
      this.mainQuery.select('trialKey'),
    ]).pipe(
      switchMap(([vendor]) => {
        if (vendor) {
          this.categoryStore.setLoading(true);
          return combineLatest([
            this.gqlService.listInMonthCategories$({
              vendor_id: vendor.id,
              month,
              budget_type: BudgetType.BUDGET_PRIMARY,
            }),
            this.gqlService.listInMonthCategories$({
              vendor_id: vendor.id,
              month,
              budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
            }),
          ]).pipe(
            tap(
              ([
                { success: primary_success, data: primary_data },
                { success: secondary_success, data: secondary_data },
              ]) => {
                this.categoryStore.remove(() => true);
                this.activityStore.remove(() => true);
                this.forecastSettingsStore.remove(() => true);
                this.overrideSettingsStore.remove(() => true);

                if (primary_success && primary_data) {
                  primary_data.categories?.forEach((category) => {
                    const obj = this.fillObj(this.categoryFilterExpenses(category));
                    this.categoryStore.add({
                      __typename: 'Category',
                      name: category.name,
                      id: category.id,
                      activity_ids: [],
                      sub_category_ids: [],
                      sub_categories: [],
                      parent_category_id: null,
                      primary_settings_id: '',
                      category_type: category.category_type,
                      secondary_settings_id: '',
                      [BudgetType.BUDGET_PRIMARY]: obj,
                      activity_checked_count: 0,
                    });
                  });
                }

                if (secondary_success && secondary_data) {
                  secondary_data.categories.forEach((category) => {
                    const obj = this.fillObj(this.categoryFilterExpenses(category));

                    this.categoryStore.update(category.id, (state) => {
                      const primary_obj = state[BudgetType.BUDGET_PRIMARY];
                      if (primary_obj) {
                        return {
                          [BudgetType.BUDGET_PRIMARY]: {
                            ...primary_obj,
                            adjustment:
                              category.category_type === CategoryType.CATEGORY_INVESTIGATOR
                                ? primary_obj.adjustment
                                : Math.round(
                                    ((primary_obj.total_override_adjusted ||
                                      primary_obj.total_accrual) -
                                      obj.total_forecast) *
                                      100
                                  ) / 100,
                          },
                          [BudgetType.BUDGET_VENDOR_ESTIMATE]: obj,
                        };
                      }

                      return {};
                    });
                  });
                }

                this.categoryStore.setLoading(false);
              }
            )
          );
        }
        this.categoryStore.setLoading(false);
        return of(null);
      })
    );
  }

  getCategories() {
    this.categoryStore.hideActivitiesWithNoRemainingCost$.next(false);
    this.categoryStore.hideForecastedActivities$.next(false);

    return combineLatest([
      this.organizationQuery.selectActive(),
      this.mainQuery.select('trialKey'),
    ]).pipe(
      switchMap(([vendor]) => {
        if (vendor && !this.organizationQuery.getPrimaryBudgetVersion(vendor.id)?.manual_forecast) {
          this.categoryStore.setLoading(true);
          return combineLatest([
            this.gqlService.listCategories$({
              vendor_id: vendor.id,
              budget_type: BudgetType.BUDGET_PRIMARY,
            }),
            this.gqlService.getUnforecastedEntityIds$(vendor.id),
          ]).pipe(
            tap(([{ data, success }, unforecastedData]) => {
              this.categoryStore.remove(() => true);
              this.activityStore.remove(() => true);
              this.forecastSettingsStore.remove(() => true);
              this.overrideSettingsStore.remove(() => true);
              const { activity_ids, category_ids } = unforecastedData.data || {};
              this.markedCategoryIds = category_ids || [];
              this.markedActivityIds = activity_ids || [];

              if (success && data) {
                data.categories?.forEach((category) => {
                  const setting = category?.budget_forecast_settings;
                  if (setting && category) {
                    const obj = this.fillObj(this.categoryFilterExpenses(category));
                    this.forecastSettingsStore.add({
                      ...setting,
                      total_cost: obj.total_cost || 0,
                      total_wp: obj.total_wp || 0,
                      total_accrual: obj.total_accrual || 0,
                      total_forecast: obj.total_forecast || 0,
                      total_override: (obj.total_accrual || 0) - (obj.total_forecast || 0),
                      unit_cost: 0,
                    });

                    this.markCategoryIfUnforecasted(category.id, setting.id);

                    let activity_checked_count = 0;
                    category?.activity_driver_settings?.forEach((x) => {
                      if (x.activity_override === true) {
                        activity_checked_count += x.setting_count;
                      }
                    });

                    this.categoryStore.add({
                      __typename: 'Category',
                      name: category.name,
                      display_label: category.display_label,
                      id: category.id,
                      activity_ids: [],
                      sub_category_ids: [],
                      sub_categories: [],
                      parent_category_id: null,
                      category_type: category.category_type,
                      primary_settings_id: setting.id,
                      secondary_settings_id: '',
                      [BudgetType.BUDGET_PRIMARY]: obj,
                      activity_checked_count,
                    });
                  }
                });
              }
              this.categoryStore.setLoading(false);
            })
          );
        }
        this.categoryStore.setLoading(false);
        return of(null);
      })
    );
  }

  markCategoryIfUnforecasted(cat_id: string, setting_id: string) {
    if (this.markedCategoryIds.find((id) => id === cat_id)) {
      this.forecastSettingsStore.ui.update(setting_id, {
        unforecasted: true,
      });
    }
  }

  markActivityIfUnforecasted(act_id: string, setting_id: string) {
    if (this.markedActivityIds.find((id) => id === act_id)) {
      this.forecastSettingsStore.ui.update(setting_id, {
        unforecasted: true,
      });
    }
  }

  async getCategory(cat_id: string) {
    this.categoryStore.ui.update(cat_id, () => ({ isLoading: true }));
    const vendor_id = this.organizationQuery.getActive()?.id;
    if (vendor_id) {
      const { success, data, errors } = await this.gqlService
        .getCategoryAndItsActivities$({
          category_id: cat_id,
          vendor_id,
          budget_type: BudgetType.BUDGET_PRIMARY,
        })
        .toPromise();

      if (success && data) {
        const sub_category_ids: string[] = [];
        if (data.categories) {
          for (const sub_cat of data.categories) {
            if (sub_cat) {
              const setting = sub_cat.budget_forecast_settings;
              if (setting) {
                const obj = this.fillObj(this.categoryFilterExpenses(sub_cat));

                this.forecastSettingsStore.add({
                  ...setting,
                  total_cost: obj.total_cost || 0,
                  total_wp: obj.total_wp || 0,
                  total_accrual: obj.total_accrual || 0,
                  total_forecast: obj.total_forecast || 0,
                  total_override: (obj.total_accrual || 0) - (obj.total_forecast || 0),
                  unit_cost: 0,
                });
                this.markCategoryIfUnforecasted(sub_cat.id, setting.id);

                let activity_checked_count = 0;
                sub_cat?.activity_driver_settings?.forEach((x) => {
                  if (x.activity_override === true) {
                    activity_checked_count += x.setting_count;
                  }
                });
                this.categoryStore.add({
                  __typename: 'Category',
                  name: sub_cat.name,
                  display_label: sub_cat.display_label,
                  id: sub_cat.id,
                  activity_ids: [],
                  sub_category_ids: [],
                  sub_categories: [],
                  parent_category_id: cat_id,
                  category_type: sub_cat.category_type,
                  primary_settings_id: setting.id,
                  secondary_settings_id: '',
                  [BudgetType.BUDGET_PRIMARY]: obj,
                  activity_checked_count,
                });
                sub_category_ids.push(sub_cat.id);
              }
            }
          }
        }

        const activity_ids: string[] = [];

        if (data.activities) {
          for (const activity of data.activities) {
            if (activity) {
              const setting = activity.budget_forecast_settings;
              const override_setting = activity.budget_override_settings;
              if (setting) {
                if (override_setting) {
                  this.overrideSettingsStore.add(override_setting);
                }
                const obj = this.fillObj(this.activityFilterExpenses(activity));

                this.forecastSettingsStore.add({
                  ...setting,
                  total_cost: obj.total_cost || 0,
                  total_wp: obj.total_wp || 0,
                  total_accrual: obj.total_accrual || 0,
                  total_forecast: obj.total_forecast || 0,
                  total_override: (obj.total_accrual || 0) - (obj.total_forecast || 0),
                  unit_cost: 0,
                });

                this.markActivityIfUnforecasted(activity.id, setting.id);

                this.activityStore.upsert(activity.id, {
                  __typename: 'Activity',
                  category_id: cat_id,
                  id: activity.id,
                  name: activity.name,
                  display_label: activity.display_label,
                  activity_type: activity.activity_type,
                  primary_settings_id: setting.id,
                  forecast_override_id: override_setting?.id || null,
                  secondary_settings_id: '',
                  unit_cost: 0,
                  uom: activity.uom,
                  unit_num: activity.unit_num,
                  [BudgetType.BUDGET_PRIMARY]: obj,
                  total_cost: 0,
                });

                activity_ids.push(activity.id);
              }
            }
          }
        }

        this.categoryStore.update(cat_id, () => {
          return {
            activity_ids,
            sub_category_ids,
            sub_categories: sub_category_ids,
          };
        });
      } else {
        this.overlayService.error(errors);
      }
    }
    this.categoryStore.ui.update(cat_id, () => ({ isLoading: false, isDetailFetched: true }));
    return null;
  }

  async getInMonthCategory(cat_id: string, month: string) {
    this.categoryStore.ui.update(cat_id, () => ({ isLoading: true }));
    const vendor_id = this.organizationQuery.getActive()?.id;
    if (vendor_id) {
      const [primary, secondary, users] = await combineLatest([
        this.gqlService.getInMonthListCategoryAndItsActivities$({
          category_id: cat_id,
          vendor_id,
          month,
          budget_type: BudgetType.BUDGET_PRIMARY,
        }),
        this.gqlService.getInMonthListCategoryAndItsActivities$({
          category_id: cat_id,
          vendor_id,
          month,
          budget_type: BudgetType.BUDGET_VENDOR_ESTIMATE,
        }),
        this.gqlService.listUserNames$(this.authQuery.getValue().trial_id).pipe(
          map((x) => {
            return { ...x, data: x.data ? x.data : null };
          })
        ),
      ]).toPromise();

      if (primary.success && primary.data) {
        const sub_category_ids: string[] = [];

        if (primary.data.categories) {
          for (const sub_cat of primary.data.categories) {
            if (sub_cat) {
              const obj = this.fillObj(this.categoryFilterExpenses(sub_cat));
              this.categoryStore.add({
                __typename: 'Category',
                name: sub_cat.name,
                id: sub_cat.id,
                activity_ids: [],
                sub_category_ids: [],
                sub_categories: [],
                parent_category_id: cat_id,
                primary_settings_id: '',
                category_type: sub_cat.category_type,
                secondary_settings_id: '',
                [BudgetType.BUDGET_PRIMARY]: obj,
                activity_checked_count: 0,
              });
              sub_category_ids.push(sub_cat.id);
            }
          }
        }

        const activity_ids: string[] = [];

        if (primary.data.activities) {
          for (const activity of primary.data.activities) {
            if (activity) {
              activity.budget_override_settings?.notes.forEach((note) => {
                const user = users.data?.find((u) => u.sub === note.created_by);
                // eslint-disable-next-line no-param-reassign
                note.created_by = `${user?.given_name} ${user?.family_name}`;
              });
              const obj = this.fillObj(this.activityFilterExpenses(activity));

              this.activityStore.add({
                __typename: 'Activity',
                category_id: cat_id,
                id: activity.id,
                name: activity.name,
                primary_settings_id: '',
                forecast_override_id: null,
                secondary_settings_id: '',
                activity_type: activity.activity_type,
                budget_override_note: JSON.stringify(activity.budget_override_settings?.notes),
                unit_cost: activity.unit_cost || 0,
                [BudgetType.BUDGET_PRIMARY]: obj,
                total_cost: 0,
              });

              activity_ids.push(activity.id);
            }
          }
        }

        if (secondary.success && secondary.data) {
          if (secondary.data.categories) {
            for (const sub_cat of secondary.data.categories) {
              if (sub_cat) {
                const obj = this.fillObj(this.categoryFilterExpenses(sub_cat));

                this.categoryStore.update(sub_cat.id, (state) => {
                  const primary_obj = state[BudgetType.BUDGET_PRIMARY];
                  if (primary_obj) {
                    return {
                      [BudgetType.BUDGET_PRIMARY]: {
                        ...primary_obj,
                        adjustment:
                          sub_cat.category_type === CategoryType.CATEGORY_INVESTIGATOR
                            ? primary_obj.adjustment
                            : (primary_obj.total_override_adjusted || primary_obj.total_accrual) -
                              obj.total_forecast,
                      },
                      [BudgetType.BUDGET_VENDOR_ESTIMATE]: obj,
                    };
                  }

                  return {};
                });
              }
            }
          }

          if (secondary.data.activities) {
            for (const activity of secondary.data.activities) {
              if (activity) {
                const obj = this.fillObj(this.activityFilterExpenses(activity));

                this.activityStore.update(activity.id, (state) => {
                  const primary_obj = state[BudgetType.BUDGET_PRIMARY];
                  if (primary_obj) {
                    return {
                      [BudgetType.BUDGET_PRIMARY]: {
                        ...primary_obj,
                        adjustment:
                          activity.activity_type === ActivityType.ACTIVITY_INVESTIGATOR
                            ? primary_obj.adjustment
                            : (primary_obj.total_override_adjusted || primary_obj.total_accrual) -
                              obj.total_forecast,
                      },
                      [BudgetType.BUDGET_VENDOR_ESTIMATE]: obj,
                    };
                  }

                  return {};
                });
              }
            }
          }
        }

        this.categoryStore.update(cat_id, () => {
          return {
            activity_ids,
            sub_category_ids,
            sub_categories: sub_category_ids,
          };
        });
      } else {
        this.overlayService.error(primary.errors);
      }
    }
    this.categoryStore.ui.update(cat_id, () => ({ isLoading: false, isDetailFetched: true }));
    return null;
  }

  add(category: CategoryModel) {
    this.categoryStore.add(category);
  }

  update(id: string, category: Partial<CategoryModel>) {
    this.categoryStore.update(id, category);
  }

  remove(id: ID) {
    this.categoryStore.remove(id);
  }

  toggleCategory(id: string) {
    const catUi = this.categoryQuery.ui.getEntity(id);
    if (catUi) {
      if (!catUi.isDetailFetched) {
        this.getCategory(id);
      }
    }
    this.categoryStore.ui.update(id, (entity) => ({ isOpen: !entity.isOpen }));
  }

  toggleInMonthCategory(id: string, month: string) {
    const catUi = this.categoryQuery.ui.getEntity(id);
    if (catUi) {
      if (!catUi.isDetailFetched) {
        this.getInMonthCategory(id, month);
      }
    }
    this.categoryStore.ui.update(id, (entity) => ({ isOpen: !entity.isOpen }));
  }

  updateCategoryOverride(cat_id: string, activityCount: number) {
    const activity_checked_count =
      activityCount + (this.categoryQuery.getEntity(cat_id)?.activity_checked_count || 0);
    this.categoryStore.update(cat_id, () => {
      return {
        id: cat_id,
        activity_checked_count,
      };
    });
  }
}
