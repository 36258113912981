<ng-template #invoiceTemp>
  <div class="flex space-x-2">
    <div class="flex items-center">
      <div class="text-sm mr-2">Filter By</div>
      <ng-select
        class="w-48 text-sm"
        [multiple]="false"
        [clearable]="true"
        [formControl]="selectedVendor"
      >
        <ng-container *ngVar="organizationQuery.allVendors$ | async as vendors">
          <ng-option [value]="''" *ngIf="vendors.length > 1">All</ng-option>
          <ng-option [value]="vendor.id" *ngFor="let vendor of vendors">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-container>
      </ng-select>
    </div>
  </div>
</ng-template>

<aux-workflow-panel
  className="mb-4"
  [workflowName]="workflowName"
  [isAdminUser]="(isAdminUser$ | async) === true"
  *ngIf="isQuarterCloseEnabled$ | async"
  [processing]="(iCloseMonthsProcessing$ | async)!"
></aux-workflow-panel>

<ng-container *ngIf="(invoiceQuery.selectLoading() | async) === false; else loading">
  <ng-container>
    <ng-container>
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <h1 class="text-2xl font-bold">Invoice Library</h1>

          <aux-input
            class="w-72 mx-7"
            placeholder="Search"
            [(ngModel)]="nameFilterValue"
            icon="search.svg"
          ></aux-input>

          <div *ngIf="authQuery.adminUser$ | async">
            <aux-checkbox
              class="mt-3 mb-3 text-sm"
              (customChange)="checkRequireCostBreakdown($event)"
            >
              Require Cost Breakdown
            </aux-checkbox>
          </div>
        </div>

        <div class="grid grid-flow-col gap-x-1.5">
          <div *ngIf="(loadingFetchBillCom$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (loadingFetchBillCom$ | async) === false &&
              (billComIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="triggerFetchBillCom()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              *auxAuthorize="{
                roles: ['ROLE_ADMIN'],
                permissions: ['PERMISSION_INVOICE_UPLOAD'],
                sysAdminsOnly: true
              }"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <div *ngIf="(loadingFetchQuickbooksOnline$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (loadingFetchQuickbooksOnline$ | async) === false &&
              (quickbooksOnlineIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              (click)="triggerFetchQuickbooksOnline()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              *auxAuthorize="{
                roles: ['ROLE_ADMIN'],
                permissions: ['PERMISSION_INVOICE_UPLOAD'],
                sysAdminsOnly: true
              }"
            >
              <span inlineSVG="cloud-download.svg" class="mr-2"></span>
              Sync Invoices
            </button>
          </div>

          <aux-export-excel-button
            [gridAPI]="gridAPI"
            [customColFilter]="customColFilter"
            [excelOptions]="excelOptions"
            [ignoreColsId]="['file', 'requireCostBreakdown']"
            [getDynamicExcelParamsCallback]="getDynamicExcelParams"
          >
          </aux-export-excel-button>

          <div [auxTooltip]="(invoiceLockTooltip$ | async)!">
            <button
              class="btn--primary pl-3"
              (click)="onNewInvoice()"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
            >
              <span inlineSVG="circle-plus.svg" class="mr-2"></span>
              Add New
            </button>
          </div>
        </div>
      </div>

      <div class="mb-24 mt-4">
        <ag-grid-angular
          id="invoicesGrid"
          class="ag-theme-aux tabular-nums"
          [rowData]="gridData$ | async"
          [gridOptions]="$any(gridOptions$ | async)"
          (firstDataRendered)="firstDataRendered($event)"
          [quickFilterText]="nameFilterValue"
        >
        </ag-grid-angular>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>
