import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { isArray } from 'lodash-es';

export interface ICellWrapperParams extends ICellRendererParams {
  customLocator?: string;
  isExpandableColumn?: boolean;
  className?: string;
}

@Component({
  selector: 'aux-ag-cell-wrapper',
  template: `<p [attr.auto-qa]="autoTestAttribute" [ngClass]="[cellClass]">
    {{ this.value }}
  </p>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCellWrapperComponent implements ICellRendererAngularComp {
  params!: ICellWrapperParams;

  group!: boolean;

  cellClass!: string;

  value!: string | number;

  autoTestAttribute!: string;

  agInit(params: ICellWrapperParams): void {
    this.params = params;
    const cellValue = this.params.valueFormatted || this.params.value;
    this.group = !!this.params.node.group;
    this.value = cellValue;

    this.cellClass = this.params.className || '';

    this.cellClass +=
      this.params.isExpandableColumn && !this.group
        ? ` ag-row-group-leaf-indent ag-row-group-indent-${this.params.node.level} `
        : '';

    if (typeof this.params?.colDef?.cellClass !== 'function') {
      this.cellClass += this.getClassName(this.params?.colDef?.cellClass || '');
    }

    this.cellClass = this.cellClass.trim();

    if (params.customLocator || params.colDef?.field) {
      this.autoTestAttribute = this.getAutoTestAttribute(params);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: ICellWrapperParams): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }

  getClassName(cellClass: string[] | string): string {
    if (isArray(cellClass)) {
      return cellClass.reduce((accum, className) => {
        return `${accum} ${className}`;
      }, '');
    }

    return cellClass;
  }
}

export const getWrapperCellOptions = (): ColDef => {
  return {
    cellRendererSelector: (params) => {
      const isGrouped = !!params.node.group;

      return {
        frameworkComponent: isGrouped ? 'agGroupCellRenderer' : AgCellWrapperComponent,
        params: { isExpandableColumn: true, className: 'text-left' },
      };
    },
    cellRenderer: AgCellWrapperComponent,
  };
};
