import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '@pipes/filter.pipe';
import { GroupByPipe } from './group-by.pipe';
import { MoneyPipe } from './money.pipe';
import { CountryPipe } from './country.pipe';
import { AuxNumberPipe } from './aux-number.pipe';
import { AccountingPipe } from './accounting.pipe';

@NgModule({
  declarations: [GroupByPipe, MoneyPipe, CountryPipe, AuxNumberPipe, AccountingPipe, FilterPipe],
  imports: [CommonModule],
  exports: [GroupByPipe, MoneyPipe, CountryPipe, AuxNumberPipe, AccountingPipe, FilterPipe],
})
export class PipeModule {}
