import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FileManagerComponent } from './file-manager.component';
import { File } from './state/file.model';
import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'aux-file-viewer',
  templateUrl: './file-viewer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileViewerComponent implements OnInit {
  @Input() fileManager: FileManagerComponent | undefined;

  @Input() onlyShowUploaded = true;

  @Input() disableFirstFileMargin = false;

  files$: Observable<File[]> | undefined;

  loading$: Observable<boolean> | undefined;

  constructor(private overlayService: OverlayService) {}

  ngOnInit(): void {
    if (this.fileManager) {
      this.files$ = this.fileManager.fileQuery.selectAll({
        filterBy: (entity) => (this.onlyShowUploaded ? entity.uploaded : true),
      });
      this.loading$ = this.fileManager.fileQuery.selectLoading();
    }
  }

  onRemove(file: File) {
    const success = this.fileManager?.removeFile(file);

    if (success) {
      this.overlayService.success(`${file.fileName} removed!`);
    }
  }
}
