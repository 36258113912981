<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loading$ | async) === false">
  <div class="h-full w-full overflow-auto">
    <ag-grid-angular
      style="height: 400px"
      class="h-full w-full ag-theme-alpine compare-grid tabular-nums"
      [gridOptions]="$any(gridOptions$ | async)"
      [rowData]="gridData$ | async"
      [domLayout]="domLayout"
      (firstDataRendered)="onDataRendered($event); autoSize()"
    >
    </ag-grid-angular>
  </div>
</ng-container>
