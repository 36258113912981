import { Injectable } from '@angular/core';
import {
  DocumentType,
  EntityType,
  GqlService,
  listDocumentsQuery,
  listOrganizationsQuery,
  listSitesQuery,
  listUserNamesWithEmailQuery,
  OrganizationType,
  UpdateDocumentInput,
} from '@services/gql.service';
import { File } from '@components/file-manager/state/file.model';
import { OverlayService } from '@services/overlay.service';
import { map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { OrganizationService } from '@models/organization/organization.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { SitesService } from '@models/sites/sites.service';
import { Utils } from '@services/utils';
import { Option } from '@components/components.type';
import { ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { AuthQuery } from '@models/auth/auth.query';
import { MainQuery } from '../../layouts/main-layout/state/main.query';

type ExtraDocumentData = [
  listOrganizationsQuery[],
  listUserNamesWithEmailQuery[],
  listSitesQuery[]
];

@Injectable({
  providedIn: 'root',
})
export class DocumentLibraryService {
  vendors: Option[] = [];

  private users: listUserNamesWithEmailQuery[] = [];

  sites: Option[] = [];

  documentTypeOptions = Utils.DOCUMENT_OPTIONS.sort(({ label }, { label: label12 }) =>
    Utils.alphaNumSort(label, label12)
  );

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Document Library',
    fileName: 'auxilius-document-library.xlsx',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.id;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'description':
          return 180;
        case 'name':
          return 300;
        case 'create_date':
          return 120;
        default:
          return 225;
      }
    },
  } as ExcelExportParams;

  constructor(
    private authQuery: AuthQuery,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private vendorsService: OrganizationService,
    private trialUserService: TrialUserService,
    private sitesService: SitesService
  ) {}

  getRequiredDictionaries() {
    return combineLatest([
      this.vendorsService.get(),
      this.trialUserService.listUserNamesWithEmail(),
      this.sitesService.listSiteNames(),
    ]).pipe(
      map((responses) => {
        return responses.map((res) => res.data || []) as ExtraDocumentData;
      }),
      tap(([vendors, users, sites]: ExtraDocumentData) => {
        this.users = users;

        this.vendors = this.sortOptions(
          vendors
            .filter((vendor) => vendor.organization_type === OrganizationType.ORGANIZATION_VENDOR)
            .map(({ id, name }) => ({
              value: id,
              label: name,
            }))
        );

        this.sites = this.sortOptions(
          sites.map(({ id, name }) => ({
            value: id,
            label: name,
          }))
        );
      })
    );
  }

  private sortOptions(options: Option[]) {
    return options.sort(({ label }, { label: label12 }) => Utils.alphaNumSort(label, label12));
  }

  prepareDataToGrid(data: listDocumentsQuery['items']) {
    return data.map((row) => {
      return {
        ...row,
        vendor_id: this.vendors.find((option) => option.value === row.vendor_id)?.value || null,
        site_id: this.sites.find((option) => option.value === row.site_id)?.value || null,
        updatedByName: this.users.find(({ sub }) => sub === row.created_by) || null,
      };
    });
  }

  getDocumentLibraryList(filter_model?: string) {
    return this.gqlService
      .listDocuments$({
        // TODO: currently we don't have pagination in system, so that's why end_row hardcoded
        end_row: 10_000,
        start_row: 0,
        filter_model,
      })
      .pipe(
        map(({ data, errors, success }) => {
          if (data?.items && success) {
            return this.prepareDataToGrid(data.items);
          }
          this.overlayService.error(errors);

          return [];
        })
      );
  }

  buildS3Path(fileName: string): string {
    const trialId = this.mainQuery.getValue().trialKey;

    return `trials/${trialId}/document-library/${fileName}`;
  }

  async uploadDocuments(
    files: File[],
    form: { vendor?: string; documentType?: DocumentType; site?: string }
  ): Promise<any> {
    const uploadResult = await Promise.all(
      files.map((file) =>
        this.gqlService
          .createDocument$({
            bucket_key: this.buildS3Path(file.key),
            name: file.fileName,
            document_type_id: form.documentType,
            is_metadata_editable: true,
            site_id: form.site,
            vendor_id: form.vendor,
          })
          .toPromise()
      )
    );

    const hasUploadError = uploadResult.some(({ errors }) => !!errors.length);

    if (hasUploadError) {
      this.overlayService.error('Files upload error!');
    } else {
      this.overlayService.success(
        `${uploadResult.length} file${uploadResult.length > 1 ? 's' : ''} uploaded successfully!`
      );
    }
    return uploadResult;
  }

  async uploadDocument(
    file: File,
    form: {
      vendor?: string;
      documentType?: DocumentType;
      site?: string;
      entity_id?: string;
      entity_type_id?: EntityType;
    },
    customKey?: string
  ): Promise<boolean> {
    const uploadResult = await this.gqlService
      .createDocument$({
        id: file.id,
        bucket_key: customKey || this.buildS3Path(file.key),
        name: file.fileName,
        document_type_id: form.documentType,
        is_metadata_editable: false,
        site_id: form.site,
        vendor_id: form.vendor,
        entity_id: form.entity_id,
        entity_type_id: form.entity_type_id,
      })
      .toPromise();
    return uploadResult.success;
  }

  async removeDocuments(ids: string[]): Promise<void> {
    const promises = ids.map((id) => this.gqlService.removeDocument$({ id }).toPromise());
    const responses = await Promise.all(promises);

    this.showOverlayByResponses(
      responses,
      `${responses.length} row${responses.length > 1 ? 's' : ''} removed successfully!`
    );
  }

  async removeDocument(id: string): Promise<boolean> {
    const response = await this.gqlService.removeDocument$({ id }).toPromise();

    if (response.success) {
      this.overlayService.success(`Document removed successfully!`);
      return true;
    }
    this.overlayService.error(response.errors);
    return false;
  }

  async updateDocuments(changesList: UpdateDocumentInput[]): Promise<void> {
    const promises = changesList.map((changes) =>
      this.gqlService.updateDocument$(changes).toPromise()
    );

    const responses = await Promise.all(promises);

    this.showOverlayByResponses(
      responses,
      `${responses.length} file${responses.length > 1 ? 's' : ''} updated successfully!`
    );
  }

  private showOverlayByResponses(responses: GraphqlResponse<any>[], successMessage: string) {
    const response = responses.find(({ errors }) => !!errors.length);

    if (response?.errors) {
      this.overlayService.error(response?.errors);
    } else {
      this.overlayService.success(successMessage);
    }
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    return {
      ...this.excelOptions,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        const colId = params.column.getColId();
        const mapColFormatterById = new Map<string, () => string>([
          ['create_date', () => Utils.awsDateFormatter(params.value)],
          ['document_type_id', () => Utils.getOptionLabel(this.documentTypeOptions, params.value)],
          ['vendor_id', () => Utils.getOptionLabel(this.vendors, params.value)],
          ['site_id', () => Utils.getOptionLabel(this.sites, params.value)],
          [
            'created_by',
            () =>
              Utils.agUserFormatter(
                this.users.find(({ sub }) => sub === params.value) as listUserNamesWithEmailQuery,
                this.authQuery.isAuxAdmin()
              ),
          ],
        ]);

        const getFormatterCol = mapColFormatterById.get(colId);

        return getFormatterCol ? getFormatterCol() : params.value;
      },
      columnKeys: [
        'description',
        'name',
        'document_type_id',
        'vendor_id',
        'site_id',
        'create_date',
        'created_by',
      ],
      prependContent: [
        [
          {
            data: { value: `Trial: ${name}`, type: 'String' },
            mergeAcross: 6,
            styleId: 'first_row',
          },
        ],
      ],
    } as ExcelExportParams;
  };
}
